<script lang="ts">
  import Star from "lucide-svelte/icons/star";
  import ExternalLink from "lucide-svelte/icons/external-link";
  import { onMount } from "svelte";
  import { Image } from "@/components/ui/image";
  import Typography from "@/components/ui/typography/typography.svelte";
  import { client } from "@/helpers/trpc/trpc-client";
  import Autoplay from "embla-carousel-autoplay";
  import emblaCarouselSvelte from "embla-carousel-svelte";
  // images imports
  import ReviewsIOLogo from "@/images/review_logo.png?raw";
  import i18n from "@/stores/i18n";

  const t = (key: string, options?: any) =>
    $i18n.t(key, { ns: "landing", ...options });

  //For schema
  interface Props {
    showSnippet?: boolean;
    sku: string;
    productName: string;
  }

  let { showSnippet = true, sku, productName }: Props = $props();

  let options = { loop: true };
  let plugins = [Autoplay({ delay: 1500 })];

  //placeholder content
  let reviews_result:
    | Awaited<ReturnType<typeof client.public.reviews.query>>
    | undefined = $state();

  let schema = $state({});

  function getMultipleRandom(arr: any[], num: number) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }

  onMount(async () => {
    reviews_result = await client.public.reviews.query();

    schema = {
      "@context": "http://schema.org",
      "@type": "Product",
      aggregateRating: {
        "@type": "AggregateRating",
        bestRating: "5",
        ratingValue: reviews_result.stats.average_rating,
        reviewCount: reviews_result.stats.total_reviews,
        worstRating: 1
      },
      review: getMultipleRandom(reviews_result.reviews, 2).map((review) => {
        return {
          "@type": "Review",
          author: {
            "@type": "Person",
            name: review.reviewer.first_name
          },
          datePublished: review.date_created,
          description: "",
          reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1"
          },
          reviewBody: review.comments.replace(/&#039;/g, "'")
        };
      }),
      gtin: sku,
      mpn: sku,
      name: productName,
      image: "https://playplex.com/images/logo.svg",
      sku: sku
    };
  });
</script>

<div
  class="container mb-8 flex flex-col items-center justify-around gap-4 md:flex-row md:items-end lg:mb-14">
  <Typography variant="h2" as="span" class="max-w-2xl"
    >{t("reviews.title")}</Typography>

  <div
    class="rounded-[8px] bg-gradient-to-r from-dark-gray via-accent to-dark-gray p-[1px]">
    <div
      class="flex min-h-10 w-full items-center justify-center gap-6 rounded-[8px] px-6 py-4"
      style="background: radial-gradient(51.8% 88.16% at 50% 100%, rgba(255, 255, 255, 0.05) 0%, rgba(18, 18, 18, 0.05) 100%), #121212;">
      <a
        href="https://www.reviews.io/company-reviews/store/smurfers"
        target="_blank">
        <Image
          class="!w-[115px] !min-w-[115px] cursor-pointer object-contain"
          src={ReviewsIOLogo} />
      </a>
      <div>
        <Typography>{t("reviews.rating_text")}</Typography>
        <Typography color="muted">{t("reviews.based_on")}</Typography>
      </div>
    </div>
  </div>
</div>

<div class="mx-auto my-4 mb-8 grid w-full gap-2 overflow-hidden md:mb-20">
  {#if reviews_result && reviews_result.reviews.length > 0}
    <div
      use:emblaCarouselSvelte={{ options, plugins }}
      id="review-cards"
      class="mx-auto flex justify-center gap-4 overflow-hidden">
      <div class="embla__container flex flex-1 items-stretch gap-6 pl-4">
        {#each reviews_result.reviews as review}
          <div
            class="relative flex flex-[1_0_285px] flex-col justify-between gap-2 rounded-xl border border-dark-gray p-4 md:flex-[1_0_350px] md:p-8">
            <Typography
              color="muted"
              variant="large"
              class="mb-4  line-clamp-5  overflow-hidden text-ellipsis !font-normal">
              {review.comments.replace(/&#039;/g, "'")}
            </Typography>

            <div class="flex flex-col gap-2">
              <div class="flex items-center justify-between gap-2">
                <div class="flex flex-col justify-around">
                  <Typography variant="large">
                    {review.reviewer.first_name}</Typography>
                  <Typography variant="large" color="muted">
                    {review.date}</Typography>
                </div>

                <ul class="flex items-center gap-1 text-sm text-accent">
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                  <Star fill="currentColor" class="h-5 w-5" />
                </ul>
              </div>

              <a
                href={`https://www.reviews.io/company-review/store/smurfers/${review.store_review_id}`}
                target="_blank"
                class="flex items-center gap-2">
                <Typography>{t("reviews.view_on_reviews")}</Typography>
                <ExternalLink class="inline-block h-6 w-6" />
              </a>
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</div>

<!--Hide reviews when cart is open
<div
  class={`reviews-io-floating-widget ${$isCartOpen ? "hidden sm:block" : ""}`}
  data-widget-id="IYeqTrlb6Oug7GS4"
  data-store-name="smurfers"
  lang="en" />
{@html `<script defer src="https://widget.reviews.io/modern-widgets/floating.js"></script>`}-->

{#if showSnippet}
  {@html `<script type="application/ld+json">
${JSON.stringify(schema)}
</script>`}
{/if}
